/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';

body{
  padding: 0;
  margin: 0;
}
.mobile-label {
	display: none;
	font-size: 14px;
	font-weight: 500;
	color: rgba(0, 0, 0, 0.54);
}

.swagger-ui .info hgroup.main a span.url {
    display: none;
}